import React, { useEffect, useCallback } from 'react';
import { Field, Button } from '@renolib/renolib-ui-kit';

import Container from '../../helpers/Container';
import useNavigateBack from '../../hooks/useNavigateBack';
import useForm from '../../hooks/useForm';
import Form from '../../helpers/Form';
import GenerateSizingNoteStyled from './GenerateSizingNote.styled';
import { productCategoriesValues } from '../../../utils/enums';

const BUILDING_COEFFICIENTS = [
    { value: 2.1, label: 'Avant 1960 = 2.1' },
    { value: 1.95, label: 'Avant 1974 = 1.95' },
    { value: 1.45, label: '1974-1984 = 1.45' },
    { value: 1.2, label: '1982-1989 = 1.2' },
    { value: 1.1, label: '1990-2000 = 1.1' },
    { value: 0.9, label: '2000-2006 = 0.9' },
    { value: 0.7, label: '2007-2012 = 0.7' },
    { value: 0.3, label: 'Après 2012 = 0.3' },
];

export const GenerateSizingNotePompeChaleur = ({ dossierCEE, onGenerate, operation }) => {
    const defaultFormState = {
        baseTemperature: -7,
        temperatureArretPAC: -15,
    };

    const sizingNoteForm = useForm({ defaultFormState });
    const { handleChange, state, updateState } = sizingNoteForm;

    const calculateDeperditions = useCallback(() => {
        const { surface, buildingCoefficient, baseTemperature, temperatureArretPAC } = state;

        if (!surface) return 0;

        const tempToUse = temperatureArretPAC > baseTemperature ? temperatureArretPAC : baseTemperature;
        const deperditions = surface * 2.5 * buildingCoefficient * (19 - tempToUse);

        return Math.round(deperditions);
    }, [state]);

    useEffect(() => {
        const deperditionValue = calculateDeperditions();
        if (state.surface && state.buildingCoefficient && state.baseTemperature && state.temperatureArretPAC)
            if (deperditionValue !== state.deperditions)
                updateState({
                    deperditions: deperditionValue,
                    puissance60: Math.round(deperditionValue * 0.6),
                    puissance130: Math.round(deperditionValue * 1.3),
                });
    }, [state.surface, state.buildingCoefficient, state.baseTemperature, state.temperatureArretPAC, state.deperditions, calculateDeperditions, updateState]);

    const isValidPuissance = () => {
        const { puissanceNominale, puissance60, puissance130 } = state;
        return puissanceNominale >= puissance60 && puissanceNominale <= puissance130;
    };

    const handleSubmit = useCallback(async () => {
        onGenerate({ sizingNoteData: state, sizingNoteCategory: productCategoriesValues.POMPE_CHALEUR, operationUUID: operation.uuid });
    }, [onGenerate, state, operation]);

    const { backUrl, backButtonTitle } = useNavigateBack({
        defaultBackUrl: '/dossiers-financement',
        defaultButtonTitle: 'Retour',
    });

    return (
        <Container backUrl={backUrl} backButtonTitle={backButtonTitle}>
            <GenerateSizingNoteStyled>
                <Form>
                    <h1 className='title'>Générer la note de dimensionnement</h1>

                    <Form.Group>
                        <Field fluid label='Surface du logement (m²)' type='number' name='surface' onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group>
                        <Field
                            fluid
                            lightBackground
                            type='select'
                            name='buildingCoefficient'
                            label='Coefficient de construction'
                            options={BUILDING_COEFFICIENTS}
                            error={sizingNoteForm.errors?.buildin}
                            onChange={sizingNoteForm.handleChange}
                            textAppearance='initial'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Field
                            fluid
                            label='Température de base (T base) :'
                            type='number'
                            name='baseTemperature'
                            defaultValue={state.baseTemperature}
                            onChange={handleChange}
                            required
                            min={-100}
                            canBeNegative
                            textAppearance='initial'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Field
                            fluid
                            label="Température d'arrêt de la PAC (T arrêt PAC) :"
                            type='number'
                            name='temperatureArretPAC'
                            defaultValue={state.temperatureArretPAC}
                            onChange={handleChange}
                            required
                            min={-100}
                            canBeNegative
                            textAppearance='initial'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Field
                            fluid
                            label='Déperditions thermiques à T base ou T arrêt PAC si T arrêt PAC > T base, en W :'
                            type='number'
                            name='deperditions'
                            value={state.deperditions}
                            disabled={true}
                            textAppearance='initial'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Field fluid label='Puissance nominale de la PAC installée en W :' type='number' name='puissanceNominale' onChange={handleChange} required textAppearance='initial' />
                    </Form.Group>

                    <Form.Group>
                        <Field
                            fluid
                            label='Puissance à 60% des déperditions à T base ou T arrêt PAC si T arrêt PAC > T base, en W:'
                            type='number'
                            name='puissance60'
                            value={state.puissance60}
                            disabled={true}
                            textAppearance='initial'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Field
                            fluid
                            label='Puissance à 130 % des déperditions à T base ou T arrêt PAC si T arrêt PAC > T base, en W:'
                            type='number'
                            name='puissance130'
                            value={state.puissance130}
                            disabled={true}
                            textAppearance='initial'
                        />
                    </Form.Group>

                    {state.puissanceNominale && !isValidPuissance() && (
                        <div className='error-message'>
                            Attention ! La note de dimensionnement que vous souhaitez générer n&apos;est pas conforme. La puissance nominale de votre PAC doit se situer entre la Puissance à 60% des
                            déperditions et la Puissance à 130 % des déperditions.
                        </div>
                    )}
                </Form>
                <footer>
                    <Button onClick={handleSubmit} disabled={!isValidPuissance()}>
                        Générer la note de dimensionnement
                    </Button>
                </footer>
            </GenerateSizingNoteStyled>
        </Container>
    );
};
