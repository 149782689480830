import _ from 'lodash';
import { v1 as uuidV1 } from 'uuid';

import { sidebarMenuRoutes, sidebarMenuTranslations } from './enums';

// Menu Toggle
export const convertToMainClass = (sidebarClass) => sidebarClass.replace('sidebar', 'main');

export const convertToTogglerClass = (sidebarClass) => sidebarClass.replace('sidebar', 'toggler');

export const joinClassNames = (...classNames) => classNames.join(' ');

// Organization
export const parseRemoteOrganizationInformation = (remoteOrganizationInformation = {}) => {
    const requiredKeys = ['details', 'addresses', 'contacts'];
    if (!Object.keys(remoteOrganizationInformation).every((value) => requiredKeys.includes(value))) throw new Error('remoteOrganizationInformation must includes keys (details, addresses, contacts)');

    const organization = {
        ...remoteOrganizationInformation.details,
        organizationAddresses: remoteOrganizationInformation.addresses,
        organizationContacts: remoteOrganizationInformation.contacts,
    };

    return organization;
};

// Object
export const compareAndExtractUpdatesIfTheyExist = (source, target) => {
    const targetKeys = Object.keys(target);

    if (targetKeys.length === 0) return null;

    let updates = null;
    for (const targetKey of targetKeys) {
        if (target[targetKey] !== source[targetKey]) {
            if (updates === null) updates = {};
            updates[targetKey] = target[targetKey];
        }
    }

    return updates;
};

export function isNullish(value) {
    return [undefined, null].includes(value);
}

export function isNonEmptyObject(object) {
    return isValidValue(object) && typeof object === 'object' && !isArray(object) && Object.keys(object).length > 0;
}

function isArray(array) {
    return Array.isArray(array);
}

export function isValidValue(value) {
    return !isNullish(value) && value !== '';
}

export function isValueStringEmpty(value) {
    return value !== '';
}
export function isDeeplyEqual(firstObject, secondObject) {
    return _.isEqual(firstObject, secondObject);
}

export function isObjectEmpty(object) {
    return object ? Object.keys(object).length === 0 : true;
}

export function roundNumberToTwoDecimal(number) {
    return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
}

export function getPropertyFromObject(object, propertyPath, defaultValue) {
    return _.get(object, propertyPath, defaultValue);
}

export function isDevelopmentMode() {
    return process.env.REACT_APP_NODE_ENV === 'development';
}

export function getFormAutocomplete() {
    return isFirefoxBrowser() ? 'off' : 'disabled';
}

function isFirefoxBrowser() {
    return navigator.userAgent.includes('Firefox');
}

export function toJSON(value) {
    try {
        return JSON.parse(value);
    } catch (error) {
        return {};
    }
}

export function toRegex(text, regexOptions = 'i') {
    if (isNullish(text)) throw new Error('You must provide <text> parameter');

    return new RegExp(text, regexOptions);
}

export function isCypressTesting() {
    return isValidValue(window.Cypress) && process.env.NODE_ENV === 'development';
}

export function capitalize(value) {
    if (typeof value !== 'string') return value;

    return value.length > 1 ? value[0].toUpperCase().concat(value.substr(1).toLowerCase()) : value.toUpperCase();
}

export function toPageName(route) {
    const pageNameStrategies = {
        [sidebarMenuRoutes.CONTRACTS]: sidebarMenuTranslations.CONTRACTS,
        [sidebarMenuRoutes.INVOICES]: sidebarMenuTranslations.INVOICES,
        [sidebarMenuRoutes.UPLOAD_DOCUMENTS]: sidebarMenuTranslations.INVOICES,
    };

    return pageNameStrategies[route] || capitalize(route.substr(1).replace(/-/g, ' '));
}

export function cloneDeep(object) {
    return _.cloneDeep(object);
}

export function isValidJSON(value) {
    try {
        JSON.parse(value);
        return true;
    } catch (error) {
        return false;
    }
}

export function extractDateParts(dateArg = new Date()) {
    const date = new Date(dateArg);

    return { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
}

export function removeNullishProperties(object = {}) {
    return _.pickBy(object, (value) => !isNullish(value));
}

export function getTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function toImageSlides(files = []) {
    return files.map((file) => (isNonEmptyObject(file) ? file : { fileDownloadUrl: file }));
}

export function isFalsy(value) {
    return !isValidValue(value) || value === false;
}

export function replacePropertyInObject(object, currentProperty, newProperty) {
    if (object[currentProperty]) {
        object[newProperty] = object[currentProperty];
        delete object[currentProperty];
    }
}

export function isNullishOrEmpty(value) {
    return isNullish(value) || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && Object.keys(value).length === 0) || (typeof value === 'string' && value.length === 0);
}

export function isNullishOrZero(value) {
    return isNullish(value) || value === 0;
}

export function isNonEmptyArray(value) {
    return Array.isArray(value) && value.length > 0;
}

export function isNonEmptyString(value) {
    return isValidValue(value) && String(value).trim().length > 0;
}

export function removeUnauthorizedRegexCharactersFromString(str) {
    return str.replace(/\\/gi, '');
}

export function arePropertiesIncludedInObject(object, properties = []) {
    const objectProperties = Object.keys(object);

    return properties.every((property) => objectProperties.includes(property));
}

export function formatEmailsForEmailsForm({ emails = [], electronicSignature = false }) {
    const formattedEmails = isNonEmptyArray(emails) ? emails : [''];
    return { emails: formattedEmails.map((email) => ({ uuid: uuidV1(), value: email })), electronicSignature };
}

export function formatUrlUUIDParameter(uuidParameter) {
    if (isNullishOrEmpty(uuidParameter)) return '';

    return uuidParameter.includes('/') ? uuidParameter : '/'.concat(uuidParameter);
}

export function toNonNullishArray(value) {
    return value || [];
}

export function toNonNullishObject(value) {
    return value || {};
}

export function toFixedDown(decimalValue, { digits = 3 } = {}) {
    const regex = new RegExp(`(\\d+\\.\\d{${digits}})`);
    const decimalValueString = String(decimalValue);
    if (decimalValueString.indexOf('.') < 0) return decimalValue;

    const match = decimalValueString.match(regex);

    return match ? parseFloat(match[1]) : decimalValue;
}

/**
 * @param {string} route
 * @returns {string}
 */
export function prefixAsFrontV2Route(route) {
    return process.env.REACT_APP_FRONT_V2 + route;
}

/**
 * @param {string} route
 */
export function openFrontV2Route(route) {
    window.open(prefixAsFrontV2Route(route), '_self');
}

/**
 * @param {SearchParams} searchParams
 * @returns
 */
export function formatSearchParamsForRequest(params = {}) {
    if (isNonEmptyObject(params.sort)) {
        params.sort = JSON.stringify(params.sort);
    } else {
        delete params.sort;
    }
    if (isNonEmptyObject(params.query)) {
        params.query = JSON.stringify(params.query);
    } else {
        delete params.query;
    }

    return params;
}
