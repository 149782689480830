import React from 'react';
import styled from 'styled-components';

import warningIcon from '../../../../assets/img/others/warning_information.svg';

import { SizedImg } from './common';

const Container = styled.div`
    padding-left: 1rem;
    padding-right: 1rem;
    p:first-child {
        margin-top: 1rem;
        margin-bottom: 0;
    }
    p:last-child {
        margin-bottom: 1rem;
    }
    ul {
        margin-left: 2rem;
    }
    li {
        list-style: disc;
        display: list-item;
    }
`;

export const InvoiceAlertMessages = ({ invoiceWarningMessages = [], invoiceCriticalMessages = [] }) => {
    return (
        <Container data-testid={'INVOICE-ALERT-MESSAGE'}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SizedImg src={warningIcon} alt='' />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p className={'fs-16'}>Votre facture contient quelques alertes</p>
            </div>
            <ul style={{ listStyle: 'none' }}>
                {invoiceCriticalMessages.map(({ message }, index) => (
                    <li key={`critical-message-${index}`} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                        <span className={'mr-2'} role={'img'} aria-label={'red-critical-alert-dot'}>
                            🔴
                        </span>
                        <div className={'fs-12'} dangerouslySetInnerHTML={{ __html: message }} />
                    </li>
                ))}
                {invoiceWarningMessages.map(({ message }, index) => (
                    <li key={`critical-message-${index}`} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                        <span className={'mr-2'} role={'img'} aria-label={'yellow-warning-dot'}>
                            🟠
                        </span>
                        <div className={'fs-12'} dangerouslySetInnerHTML={{ __html: message }} />
                    </li>
                ))}
                <li key={`critical-message-support mt-2`} style={{ listStyle: 'none', display: 'flex', justifyContent: 'center' }}>
                    <span className={'mr-2'} />
                    <span className={'mr-2 fs-12 font-weight-bold'}>Contactez le support si nécessaire</span>
                </li>
            </ul>
        </Container>
    );
};
