import { authHeaders, endpointV2, getAuthHeadersWithJSONContentType } from '../../api';
import { DOSSIERS_CEE_ADMINISTRATOR_ROUTE } from '../../api/routes';
import storage from '../../utils/storage';

function getBaseUrl(organization) {
    return `/organizations/${organization?.uuid || storage.getOrganizationUUID()}/dossiers-cee`;
}

async function getDossiersCount(query) {
    return endpointV2.get(`${getBaseUrl()}/count`, {
        headers: authHeaders(),
        params: {
            ...query,
        },
    });
}

async function getDossiersCEE(options) {
    return endpointV2.get(getBaseUrl(), {
        headers: authHeaders(),
        params: options,
    });
}

async function searchDossiersCEE(options) {
    return endpointV2.get(`${getBaseUrl()}/search`, {
        headers: authHeaders(),
        params: { ...options },
    });
}

async function getDossierCEE(dossierCEEUUID) {
    return endpointV2.get(`${getBaseUrl()}/${dossierCEEUUID}`, {
        headers: authHeaders(),
    });
}

async function updateDossierCEE(dossierCEEUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${dossierCEEUUID}`, updates, {
        headers: getAuthHeadersWithJSONContentType(),
    });
}

async function downloadDossierCEE(dossierCEEUUID) {
    return endpointV2.get(`${getBaseUrl()}/${dossierCEEUUID}/download`, {
        headers: authHeaders(),
        responseType: 'arraybuffer',
    });
}

async function sendDossierCEE(dossierCEEUUID, sendingData) {
    return endpointV2.put(`${getBaseUrl()}/${dossierCEEUUID}/send-for-back-office-verification`, sendingData, {
        headers: authHeaders(),
    });
}

async function getDossiersCEEAnalytics() {
    return endpointV2.get(`/organizations/${storage.getOrganizationUUID()}/analytical-data/dossiers-cee`, {
        headers: authHeaders(),
    });
}

async function refreshDossierCEERenovationAddress(dossierCEEUUID) {
    return endpointV2.put(
        `${getBaseUrl()}/${dossierCEEUUID}/renovation-address-household-income-bonification-refresh`,
        {},
        {
            headers: getAuthHeadersWithJSONContentType(),
        }
    );
}

async function saveAdvancePaymentAgreementResponse(dossierCEEUUID, data = {}) {
    return endpointV2.put(`${getBaseUrl()}/${dossierCEEUUID}/save-advance-payment-agreement-response`, data, {
        headers: getAuthHeadersWithJSONContentType(),
    });
}

async function updateDossierCEETaxImposition(dossierCEEUUID, updates, organization) {
    return endpointV2.put(`${getBaseUrl(organization)}/${dossierCEEUUID}/update-tax-imposition`, updates, {
        headers: getAuthHeadersWithJSONContentType(),
    });
}

async function getDossierCEEForAdministrator(dossierCEEUUID) {
    return endpointV2.get(`${DOSSIERS_CEE_ADMINISTRATOR_ROUTE}/${dossierCEEUUID}`, {
        headers: authHeaders(),
    });
}

export const updateDossierCeeForDocumentRegeneration = async (dossierCEEUUID, data) => {
    return endpointV2.put(`${getBaseUrl()}/${dossierCEEUUID}/regenerate-document`, data, {
        headers: getAuthHeadersWithJSONContentType(),
    });
};

async function deleteBeneficiaryControlForm({ uuidInvoice, dossierCEEId }) {
    return endpointV2.delete(`/organizations/${storage.getOrganizationUUID()}/invoices/${uuidInvoice}/delete-beneficiary-control-form`, {
        headers: authHeaders(),
        params: { dossierCEEId },
    });
}

export default {
    getDossiersCEE,
    getDossierCEE,
    getDossiersCount,
    searchDossiersCEE,
    updateDossierCEE,
    downloadDossierCEE,
    sendDossierCEE,
    getDossiersCEEAnalytics,
    refreshDossierCEERenovationAddress,
    saveAdvancePaymentAgreementResponse,
    updateDossierCEETaxImposition,
    getDossierCEEForAdministrator,
    updateDossierCeeForDocumentRegeneration,
    deleteBeneficiaryControlForm,
};
