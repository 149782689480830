import companies from '../data/companies';
import { DOCUMENT_CHECK_REPORT_PAGE_URL, DOSSIERS_PAGE_URL } from '../routes';

import { addOtherDocumentButton } from './dossier_cee/format_document/addOtherDocumentButton';
import { formatAttestationPublicLightningAsDocument } from './dossier_cee/format_document/formatAttestationPublicLightning';
import { formatAuditAfterRenovationAsDocument } from './dossier_cee/format_document/formatAuditAfterRenovation';
import { formatAuditBeforeRenovationAsDocument } from './dossier_cee/format_document/formatAuditBeforeRenovation';
import { formatCadastralPlotAsDocument } from './dossier_cee/format_document/formatCadastralPlot';
import { formatCadreContributionAsDocument } from './dossier_cee/format_document/formatCadreContribution';
import { extractCancelledDocuments } from './dossier_cee/format_document/formatCancelledDocument';
import { formatCertificateOfCompliancyAsDocument } from './dossier_cee/format_document/formatCertificateOfCompliancy';
import { formatClaimAssignmentAsDocument } from './dossier_cee/format_document/formatClaimAssignment';
import { formatRenovationGlobalCofracBeforeWork } from './dossier_cee/format_document/formatCofracBeforeWork';
import { formatCommissioningReportAsDocument } from './dossier_cee/format_document/formatCommissioningReport';
import { formatDomiciliationCertificateAsDocument } from './dossier_cee/format_document/formatDomiciliationCertificate';
import { formatInvoiceAsDocument } from './dossier_cee/format_document/formatInvoice';
import { extractMandatoryAttestation } from './dossier_cee/format_document/formatMandatoryAttestations';
import { formatMovingCertificateDocument } from './dossier_cee/format_document/formatMovingCertificate';
import { formatPostRenovationReportAsDocument } from './dossier_cee/format_document/formatPostRenovationReport';
import { formatQuotationAsDocument } from './dossier_cee/format_document/formatQuotation';
import { formatRenovationGlobalAmo } from './dossier_cee/format_document/formatRenovationGlobalAmo';
import { formatRenovationGlobalAuditAsDocument } from './dossier_cee/format_document/formatRenovationGlobalAuditAsDocument';
import { formatRenovationGlobalAuditQuotation } from './dossier_cee/format_document/formatRenovationGlobalAuditQuotation';
import { formatRenovationGlobalCofracAfterWork } from './dossier_cee/format_document/formatRenovationGlobalCofracAfterWork';
import { formatRenovationGlobalAdvisedOperations } from './dossier_cee/format_document/formatRenovationGlobaleAdvisedOperations';
import { formatRenovationGlobalMaPrimeRenovWorkingStatement } from './dossier_cee/format_document/formatRenovationGlobaleMaPrimeRenovWorkingStatement';
import { formatRenovationGlobalTechnicians } from './dossier_cee/format_document/formatRenovationGlobaleTechnicianDocuments';
import { extractRgeDocuments } from './dossier_cee/format_document/formatRGEDocuments';
import { formatSitePhotosAsDocument } from './dossier_cee/format_document/formatSitePhoto';
import { formatSitePhotosBeforeRenovationAsDocument } from './dossier_cee/format_document/formatSitePhotoBeforeRenovation';
import { formatSitePhotosAfterRenovationAsDocument } from './dossier_cee/format_document/formatSitePhotosAfterRenovation';
import { formatSizingNoteAsDocument } from './dossier_cee/format_document/formatSizingNote';
import { formatSolarFactorCalculationNoteAsDocument } from './dossier_cee/format_document/formatSolarFactorCalculationNote';
import { formatSwornStatementAsDocument } from './dossier_cee/format_document/formatSwornStatement';
import { formatTaxNoticesAsDocument } from './dossier_cee/format_document/formatTaxNotice';
import { formatTechnicalSheetAsDocument } from './dossier_cee/format_document/formatTechnicalSheet';
import { extractTechniciansStatusReport } from './dossier_cee/format_document/formatTechnicianStatusReport';
import { extractAdditionalDocuments } from './dossier_cee/format_document/getAdditionalDocument';
import { dispatchSpecificRules } from './specific_rules/specificRulesDispatcher';
import contractHelper from './contract-helper';
import entitiesUtils from './entities-utils';
import { alertTypes, contractNegotiators, DOCUMENT_CHECK_STATUSES, dossierCEEDocumentTypes, dossierCEEStatuses, dossierInternalStatuses, invoiceTypes, quotationStatuses } from './enums';
import { applicationPages } from './enums';
import history from './history';
import invoiceHelper from './invoice-helper';
import { isMixedMandateStatus } from './ma-prime-renov.helper';
import { DOSSIER_CEE_CANCELLED_BY_BACK_OFFICE, DOSSIER_CEE_CANCELLED_DOCUMENT, DOSSIER_CEE_NOT_DOWNLOADABLE, DOSSIER_CEE_NOT_SENDABLE } from './messages';
import operationSheetNames from './operation-sheet-names';
import quotationHelper, { doesTheEntityHasARenovationGlobalOrARenovationPremium } from './quotation-helper';
import { getOperationOfRenovationGlobalFromOperations } from './renovation-global-helper';
import { formatUrlUUIDParameter, isNonEmptyObject, isNullishOrEmpty } from '.';

export const shouldRetrieveAllOccupants = (quotation) =>
    dispatchSpecificRules('shouldRetrieveAllOccupants', { document: quotation }) || doesTheEntityHasARenovationGlobalOrARenovationPremium(quotation);

function getDossierCEEStatusLabel(status) {
    const statusesTranslations = {
        [dossierCEEStatuses.PROCESSING]: 'En création',
        [dossierCEEStatuses.COMPLETED]: 'Complet',
        [dossierCEEStatuses.SENT]: 'Envoyé',
        [dossierCEEStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION]: 'Envoyé',
        [dossierCEEStatuses.CANCELLED]: 'Annulé',
        [dossierCEEStatuses.CANCELLED_FROM_BACK_OFFICE]: 'Annulé',
    };

    return statusesTranslations[status];
}

function formatDossiersCEEForTable(dossiersCEE = []) {
    return dossiersCEE.map((dossierCEE) => {
        const { uuid, number, sendingDate, quotation, invoice, status } = dossierCEE;

        return {
            uuid,
            number,
            createdBy: dossierCEE?.createdBy,
            delegateeName: getContractDelegateeName(dossierCEE),
            customerName: quotationHelper.formatCustomerName(quotation.customer),
            sendingDate,
            operations: quotationHelper.getOperationsNames(quotation.operations),
            energySavingCertificatePremium: getDossierCEEEnergySavingCertificatePremium({ quotation, invoice }),
            status,
            hasAdministrativeProblem: dossierCEE.hasAdministrativeProblem,
            dossierCEE,
        };
    });
}

function getContractDelegateeName(dossierCEE) {
    return dossierCEE.quotation?.contract?.delegateeName || '';
}

function formatSendingDate({ sendingDateForBackOfficeVerification, sendingDate }) {
    const finalSendingDate = sendingDateForBackOfficeVerification || sendingDate;

    return finalSendingDate ? entitiesUtils.formatDate(finalSendingDate) : 'Non envoyé';
}
function getExtraRenovationGlobalDocument(dossierCEE) {
    const { quotation } = dossierCEE;
    const renovationGlobal = getOperationOfRenovationGlobalFromOperations(quotation);
    if (!renovationGlobal) return [];
    return [formatRenovationGlobalAuditAsDocument(dossierCEE), formatRenovationGlobalTechnicians(dossierCEE)];
}

function extractDocumentsFromDossierCEE(dossierCEE, cancelledDocument) {
    if (!dossierCEE) return [];

    const mandatoryDocuments = extractMandatoryDocuments(dossierCEE, cancelledDocument);

    const extraRenovationGlobalDocument = getExtraRenovationGlobalDocument(dossierCEE);

    const additionalDocuments = extractAdditionalDocuments(dossierCEE);
    const cancelledDocuments = extractCancelledDocuments(dossierCEE);
    const rgeDocuments = extractRgeDocuments(dossierCEE);
    const mandatoryAttestations = extractMandatoryAttestation(dossierCEE);
    const techniciansStatusReport = extractTechniciansStatusReport(dossierCEE);

    return sortDocumentsByPositionAscendant([
        ...mandatoryDocuments,
        ...extraRenovationGlobalDocument,
        ...additionalDocuments,
        ...cancelledDocuments,
        ...rgeDocuments,
        ...mandatoryAttestations,
        ...techniciansStatusReport,
        addOtherDocumentButton(dossierCEE),
    ]);
}

function extractMandatoryDocuments(dossierCEE, cancelledDocument) {
    const { mandatoryDocuments = [] } = dossierCEE;

    const mandatoryDocumentStrategies = {
        [dossierCEEDocumentTypes.QUOTATION]: formatQuotationAsDocument,
        [dossierCEEDocumentTypes.CADRE_CONTRIBUTION]: formatCadreContributionAsDocument,
        [dossierCEEDocumentTypes.INVOICE]: formatInvoiceAsDocument,
        [dossierCEEDocumentTypes.POST_RENOVATION_REPORT]: formatPostRenovationReportAsDocument,
        [dossierCEEDocumentTypes.SIZING_NOTE]: formatSizingNoteAsDocument,
        [dossierCEEDocumentTypes.SWORN_STATEMENT]: formatSwornStatementAsDocument,
        [dossierCEEDocumentTypes.CADASTRAL_PLOT]: formatCadastralPlotAsDocument,
        [dossierCEEDocumentTypes.DOMICILIATION_CERTIFICATE]: formatDomiciliationCertificateAsDocument,
        [dossierCEEDocumentTypes.SOLAR_FACTOR_CALCULATION_NOTE]: formatSolarFactorCalculationNoteAsDocument,
        [dossierCEEDocumentTypes.TECHNICAL_SHEET]: formatTechnicalSheetAsDocument,
        [dossierCEEDocumentTypes.SITE_PHOTO]: formatSitePhotosAsDocument,
        [dossierCEEDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION]: formatSitePhotosBeforeRenovationAsDocument,
        [dossierCEEDocumentTypes.SITE_PHOTO_AFTER_RENOVATION]: formatSitePhotosAfterRenovationAsDocument,
        [dossierCEEDocumentTypes.MOVING_CERTIFICATE]: formatMovingCertificateDocument,
        [dossierCEEDocumentTypes.CLAIM_ASSIGNMENT]: formatClaimAssignmentAsDocument,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE]: formatRenovationGlobalAmo,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_COFRAC_BEFORE_WORK]: formatRenovationGlobalCofracBeforeWork,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_COFRAC_AFTER_WORK]: formatRenovationGlobalCofracAfterWork,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST]: formatRenovationGlobalAdvisedOperations,
        [dossierCEEDocumentTypes.TAX_NOTICE]: formatTaxNoticesAsDocument,
        [dossierCEEDocumentTypes.COMMISSIONING_REPORT]: formatCommissioningReportAsDocument,
        [dossierCEEDocumentTypes.AUDIT_BEFORE_RENOVATION]: formatAuditBeforeRenovationAsDocument,
        [dossierCEEDocumentTypes.AUDIT_AFTER_RENOVATION]: formatAuditAfterRenovationAsDocument,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT]: formatRenovationGlobalMaPrimeRenovWorkingStatement,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_AUDIT_QUOTATION]: formatRenovationGlobalAuditQuotation,
        [dossierCEEDocumentTypes.ATTESTATION_PUBLIC_LIGHTNING]: formatAttestationPublicLightningAsDocument,
        [dossierCEEDocumentTypes.CERTIFICATE_OF_COMPLIANCY]: formatCertificateOfCompliancyAsDocument,
    };

    return mandatoryDocuments
        .map(({ type, valid }) => {
            const document = mandatoryDocumentStrategies[type](dossierCEE, cancelledDocument);
            if (document) return { ...document, valid };
            return undefined;
        })
        .filter((document) => document);
    //add the render component here;
}

function sortDocumentsByPositionAscendant(documents = []) {
    return documents.sort((prevDocument, nextDocument) => {
        if (prevDocument.position < nextDocument.position) return -1;
        else if (prevDocument.position > nextDocument.position) return 1;

        return 0;
    });
}

function isDossierCEEDownloadable({ status } = {}, downloadable = false) {
    return [dossierCEEStatuses.COMPLETED, dossierCEEStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION, dossierCEEStatuses.SENT].includes(status) || downloadable;
}

function isDossierCEESendable({ status } = {}) {
    return [dossierCEEStatuses.COMPLETED, dossierCEEStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION, dossierCEEStatuses.SENT].includes(status);
}

function getDossierCEESendButtonMessage(dossierCEE = {}) {
    switch (dossierCEE.status) {
        case dossierCEEStatuses.COMPLETED:
        case dossierCEEStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION:
        case dossierCEEStatuses.SENT:
            return '';
        case dossierCEEStatuses.PROCESSING:
            return DOSSIER_CEE_NOT_SENDABLE;
        case dossierCEEStatuses.CANCELLED:
            return DOSSIER_CEE_CANCELLED_DOCUMENT;
        case dossierCEEStatuses.CANCELLED_FROM_BACK_OFFICE:
            return DOSSIER_CEE_CANCELLED_BY_BACK_OFFICE;
        default:
            return '';
    }
}

function getDossierCEEDownloadButtonMessage(dossierCEE = {}, downloadable = false) {
    if (downloadable) return '';

    switch (dossierCEE.status) {
        case dossierCEEStatuses.COMPLETED:
        case dossierCEEStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION:
        case dossierCEEStatuses.SENT:
            return '';
        case dossierCEEStatuses.PROCESSING:
            return DOSSIER_CEE_NOT_DOWNLOADABLE;
        case dossierCEEStatuses.CANCELLED:
            return DOSSIER_CEE_CANCELLED_DOCUMENT;
        case dossierCEEStatuses.CANCELLED_FROM_BACK_OFFICE:
            return DOSSIER_CEE_CANCELLED_BY_BACK_OFFICE;
        default:
            return '';
    }
}

function getDossierCEEZipFilename(dossierCEE = {}) {
    const { number, quotation } = dossierCEE;

    const formattedDossierCEENumber = number.replace(/-/g, '_');

    const customerName = quotationHelper.formatCustomerName(quotation.customer, { includeLastName: true });
    const formattedCustomerName = customerName.toUpperCase().trim().replace('&', '-');

    return `DOSSIER_CEE_COMPLET_${formattedDossierCEENumber}_${formattedCustomerName}.zip`;
}

function getPDFViewerMinHeight(documents = []) {
    return documents.length > 4 ? '' : '80vh';
}

function getImageViewerMinHeight(documents = []) {
    return documents.length > 4 ? '100%' : '80vh';
}
function getDossierCEEOperationsTotalVolume(dossierCEE = {}) {
    const { quotation, invoice } = dossierCEE;

    return quotationHelper.getOperationsTotalVolume(invoiceHelper.isInvoiceActive(invoice) ? invoice : quotation);
}

function getDossierCEEEnergySavingCertificatePremium(dossierCEE = {}) {
    const { quotation, invoice } = dossierCEE;

    return invoiceHelper.isInvoiceActive(invoice) ? invoice.energySavingCertificatePremium : quotation.energySavingCertificatePremium;
}

function handleOpenDossierCEE({ uuid }) {
    history.push(`/dossiers-financement/${uuid}`);
}

function canShowCustomerColumn(targetPage) {
    return targetPage !== applicationPages.CUSTOMER;
}

function canShowTableMenuActions(targetPage) {
    return targetPage === applicationPages.DOSSIERS_CEE;
}

function getSendDossierCEEModalTitle(dossierCEE) {
    const contractDelegateeName = getContractDelegateeName(dossierCEE);

    return `Envoi du dossier ${dossierCEE.number}`.concat(contractDelegateeName ? ` à ${contractDelegateeName}` : '');
}

function getContractEmail(dossierCEE) {
    return dossierCEE.quotation?.contract?.billingEmail || '';
}

function getSendDossierCEESuccessMessage(dossierCEE, sendingData) {
    const sendingEmail = sendingData.email || getContractEmail(dossierCEE);
    const contractDelegateeName = getContractDelegateeName(dossierCEE);

    return `Votre dossier <span class='font-weight-md'>${dossierCEE.number}</span> a bien été envoyé à l'obligé. <span class='font-weight-md'>${contractDelegateeName}</span> à l'adresse email <span class='font-weight-md'>${sendingEmail}</span>`;
}

function canShowAdvancePaymentAgreementDialog(dossierCEE, organization = {}, { disabled = false } = {}) {
    if (disabled) return false;
    if (
        dossierCEE?.energySavingCertificatePremiumAdvancePaymentResponseSaved ||
        isNullishOrEmpty(dossierCEE?.invoice?.finalizationDate) ||
        isNullishOrEmpty(dossierCEE?.invoice?.contract) ||
        isOrganizationNotAuthorizedForInvoiceAdvancePayment(organization)
    ) {
        return false;
    }

    const { contract, type } = dossierCEE.invoice;

    return type === invoiceTypes.CLASSIC && [companies.VITOGAZ.siren, companies.TEKSIAL.siren].includes(contract.delegateeSiren) && contract.negotiator === contractNegotiators.RENOLIB;
}

function isOrganizationNotAuthorizedForInvoiceAdvancePayment(organization) {
    return [companies.ENR_GP, companies.ENR_GUYANE, companies.ENR_FREE_MARTINIQUE, companies.ZENITH_SOLAR_DISTRIBUTION, companies.GMG_TECH_SAS, companies.SOGEFA]
        .map(({ siren }) => siren)
        .includes(organization?.siren);
}

function isBeneficiaryApplicationAccessSendable(dossierCEE = {}) {
    if (isNullishOrEmpty(dossierCEE?.quotation) || ['demo', 'production'].includes(process.env.REACT_APP_NODE_ENV)) return false;

    return [quotationStatuses.COMPLETED, quotationStatuses.SENT, quotationStatuses.SIGNED, quotationStatuses.SIGNED_AND_FINALIZED_RELATED_INVOICE].includes(dossierCEE.quotation.status);
}

function translateCheckStatus(status) {
    if (isNullishOrEmpty(status)) return '';

    return {
        [DOCUMENT_CHECK_STATUSES.PENDING]: 'En attente',
        [DOCUMENT_CHECK_STATUSES.APPROVED]: 'Validé',
        [DOCUMENT_CHECK_STATUSES.REJECTED]: 'Rejeté',
    }[status];
}

function isDocumentCheckRequired(document) {
    return document.isCheckable && document?.checkReport?.status === DOCUMENT_CHECK_STATUSES.PENDING;
}

function getDocumentCheckReportPageUrl(dossierUUIDUrlParam, uploadedDocumentUUIDUrlParam) {
    return [DOSSIERS_PAGE_URL, formatUrlUUIDParameter(dossierUUIDUrlParam), DOCUMENT_CHECK_REPORT_PAGE_URL, formatUrlUUIDParameter(uploadedDocumentUUIDUrlParam)].join('');
}

function getDossierPageUrl(dossierUUIDUrlParam) {
    return [DOSSIERS_PAGE_URL, formatUrlUUIDParameter(dossierUUIDUrlParam)].join('');
}

function extractAlertsFromDossier(dossier, documentType) {
    if (isNullishOrEmpty(dossier) || isNullishOrEmpty(documentType)) return [];

    const alerts = [];
    extractTimestampedPhotosAlert(dossier, documentType, alerts);

    return alerts;
}

function extractTimestampedPhotosAlert({ mandatoryDocuments = [], quotation } = {}, documentType, alerts = []) {
    if (documentType !== dossierCEEDocumentTypes.TIME_STAMPED_PHOTO) return;

    const timestampedPhotosMandatoryDocument = mandatoryDocuments.find(({ type }) => type === dossierCEEDocumentTypes.TIME_STAMPED_PHOTO);
    if (isNonEmptyObject(timestampedPhotosMandatoryDocument)) {
        const requiredCount = timestampedPhotosMandatoryDocument?.data?.requiredCount;
        const matchingOperation = quotation.operations.find((operation) => [operationSheetNames.BAR_TH_173V_A56_1_CDP].includes(operation.operationSheetName));

        if (isNonEmptyObject(matchingOperation) && requiredCount)
            alerts.push({
                message:
                    "Le nombre de photos horodatées doit correspondre au nombre d'équipements installés (robinets thermostatiques et régulateurs électroniques) + l'unité centrale. \nLe nombre de photos attendues est : ".concat(
                        requiredCount
                    ),
                type: alertTypes.WARNING,
            });
    }
}

function isDossierCEECancelable(dossier = {}) {
    const { internalStatus = '' } = dossier;

    return internalStatus !== dossierInternalStatuses.VALIDATED_BY_RENOLIB;
}

function isBeneficiaryControlFormRequired(quotation) {
    return isMixedMandateStatus(quotation) || (quotation.energySavingCertificatePremium > 0 && contractHelper.isContractNegociatedByRenolib(quotation));
}

/**
 * @param {Dossier[]} dossiers
 */
function formatDossiersForTable(dossiers = []) {
    return dossiers.map((dossier) => {
        return {
            ...dossier,
            dossier,
        };
    });
}

export default {
    getDossierCEEStatusLabel,
    formatDossiersCEEForTable,
    extractDocumentsFromDossierCEE,
    getDossierCEEZipFilename,
    getPDFViewerMinHeight,
    getImageViewerMinHeight,
    formatSendingDate,
    getDossierCEEOperationsTotalVolume,
    getDossierCEEEnergySavingCertificatePremium,
    handleOpenDossierCEE,
    canShowCustomerColumn,
    canShowTableMenuActions,
    getSendDossierCEEModalTitle,
    getContractEmail,
    getSendDossierCEESuccessMessage,
    canShowAdvancePaymentAgreementDialog,
    getDossierCEEDownloadButtonMessage,
    getDossierCEESendButtonMessage,
    isDossierCEEDownloadable,
    isDossierCEESendable,
    isBeneficiaryApplicationAccessSendable,
    translateCheckStatus,
    isDocumentCheckRequired,
    getDocumentCheckReportPageUrl,
    getDossierPageUrl,
    extractAlertsFromDossier,
    isDossierCEECancelable,
    isBeneficiaryControlFormRequired,
    formatDossiersForTable,
};
