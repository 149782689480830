import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../helpers/Container';
import { DrawCadastralDocument } from '../../core/dossier_cee/helpers/DrawCadastralDocument';
import dialogsActions from '../../../store/actions/ui/dialogs';
import { ParcelleCadastralWarningDialog } from '../../helpers/dialogs/customDialogs/ParcelleCadastralWarningDialog';
import useNavigateBack from '../../hooks/useNavigateBack';

export const CreateParcelleCadastrale = ({ dossierCEE, onCreate }) => {
    const dispatch = useDispatch();
    const { backUrl, backButtonTitle } = useNavigateBack({ defaultBackUrl: '/dossiers-financement', defaultButtonTitle: 'Retour au dossier de financement' });

    const onGetAddressError = useCallback(
        (errorMessage) => {
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
        },
        [dispatch]
    );

    useEffect(() => {
        if (dossierCEE?.addressGeoCoordinates && dossierCEE?.addressGeoCoordinates?.extraMessage) {
            dispatch(dialogsActions.showWarningDialog({ renderCustomComponent: () => <ParcelleCadastralWarningDialog text={`${dossierCEE?.addressGeoCoordinates?.extraMessage}`} /> }));
        }
    }, [dossierCEE, dispatch]);

    if (!dossierCEE) return null;
    const { quotation, addressGeoCoordinates = {} } = dossierCEE;

    return (
        <>
            <Container backUrl={backUrl} backButtonTitle={backButtonTitle}>
                <div>
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{quotation?.address?.fullAddress}</span>
                </div>
                <DrawCadastralDocument
                    address={quotation?.address?.fullAddress}
                    city={quotation?.address?.city}
                    postalCode={quotation?.address?.postalCode}
                    latitude={addressGeoCoordinates?.latitude}
                    longitude={addressGeoCoordinates?.longitude}
                    onSave={onCreate}
                    onGetAddressError={onGetAddressError}
                />
            </Container>
        </>
    );
};
