import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { UPLOAD_DOCUMENTS_QUERY_KEY } from '../../config/constants';
import dossierCEEActions from '../../store/actions/dossier-cee.actions';
import dialogsActions from '../../store/actions/ui/dialogs';
import uploadDocumentActionsService from '../../store/actions/upload-document.actions';
import { cloneDeep, isNonEmptyObject, isNullishOrEmpty } from '../../utils';
import downloadFileHelper from '../../utils/download-file-helper';
import { applicationPages, dossierCEEDocumentTypes } from '../../utils/enums';
import uploadDocumentFormHelper from '../../utils/upload-document-form-helper';

import useForm from './useForm';
import { useRole } from './useRole';

export const useUploadDocuments = ({ onDocumentCreated, defaultFormState, uploadDocumentActions = uploadDocumentActionsService, dossierCEE }) => {
    const dispatch = useDispatch();
    const { hasActions } = useRole([]);
    const { message } = useSelector((state) => state.uploadDocumentsState);
    const { updateState, state, handleChange, errors, reset, setErrors } = useForm({ defaultFormState });
    const queryClient = useQueryClient();

    function handleSuccess() {
        queryClient.invalidateQueries({ queryKey: [UPLOAD_DOCUMENTS_QUERY_KEY] });
        if (onDocumentCreated) return onDocumentCreated();
    }

    function handleCreateUploadDocumentFromDossierCEEPage(formData, additionalParams) {
        dispatch(uploadDocumentActions.createUploadDocumentInDossierCEE()({ formData, hasAccessToNewView: hasActions('hasAccesToPrimeNewView'), onSuccess: handleSuccess, ...additionalParams }));
    }

    function handleCreateUploadDocumentFromUploadDocumentPage(formData, additionalParams) {
        dispatch(uploadDocumentActions.createUploadDocument()({ formData, hasAccessToNewView: hasActions('hasAccesToPrimeNewView'), onSuccess: handleSuccess, ...additionalParams }));
    }

    function validateUploadDocumentForm({ applicationPage = applicationPages.DOSSIER_CEE } = {}) {
        const { validForm, formData, formErrors } = uploadDocumentFormHelper.validateUploadDocumentForm(cloneDeep(state));

        if (validForm) {
            if (state.documentType === dossierCEEDocumentTypes.TAX_NOTICE) {
                return dispatch(dossierCEEActions.updateDossierCEETaxImposition(state, state.dossierCEE, onDocumentCreated));
            }

            const additionalParams = {};
            if (isNonEmptyObject(dossierCEE?.organization)) {
                additionalParams.organization = dossierCEE.organization;
            }

            const createUploadDocumentCallback = {
                [applicationPages.DOSSIER_CEE]: handleCreateUploadDocumentFromDossierCEEPage,
                [applicationPages.UPLOAD_DOCUMENT]: handleCreateUploadDocumentFromUploadDocumentPage,
            }[applicationPage];
            if (createUploadDocumentCallback) createUploadDocumentCallback(formData, additionalParams);
        }
        if (!validForm) setErrors(formErrors);
    }

    const checkIfDocumentExist =
        (state) =>
        ({ value }) => {
            dispatch(uploadDocumentActions.checkIfDocumentExists()({ value, documentType: state.documentType, operationUUID: state.operationUUID }));
        };

    function handleDeleteUploadDocument({ uuid }) {
        dispatch(
            dialogsActions.showConfirmDialog({
                message: 'Confirmez-vous la suppression de ce document ?',
                onConfirm: () => processDeleteUploadDocument(uuid),
            })
        );
    }

    function processDeleteUploadDocument(uploadDocumentUUID) {
        dispatch(uploadDocumentActions.deleteUploadDocument()({ uploadDocumentUUID }));
    }

    function handleDownloadUploadDocument(uploadDocument) {
        if (uploadDocument) downloadFileHelper.downloadFile(uploadDocument);
    }

    function handleResetDocumentCheckMessage() {
        dispatch(uploadDocumentActions.resetDocumentCheckMessage());
    }

    useEffect(() => {
        if (isNullishOrEmpty(defaultFormState)) return;

        reset(defaultFormState);
    }, [defaultFormState, reset]);

    return {
        checkIfDocumentExist,
        processDeleteUploadDocument,
        handleDeleteUploadDocument,
        handleDownloadUploadDocument,
        validateUploadDocumentForm,
        updateState,
        state,
        handleChange,
        errors,
        message,
        reset,
        handleResetDocumentCheckMessage,
    };
};
