import _ from 'lodash';

export const batTh142VA543AndProductsLabels = Object.freeze({
    LABEL_DESTRATIFICATION_LOCAL_NAME: 'Nom du local',
    LABEL_DESTRATIFICATION_LOCAL_TYPE: 'Type de local',
    LABEL_DESTRATIFICATION_SPORT_OR_TRANSPORT: 'Local dédié aux activités sportives ou aux transports',
    LABEL_DESTRATIFICATION_COMMERCE_CULTE_CONF: 'Local dédié au commerce, aux spectacles ou conférences, aux loisirs ou aux lieux de culte',
    LABEL_DESTRATIFICATION_ECOULEMENT_TYPE: 'Le système de destratification d’air est à écoulement',
    LABEL_VERTICAL_DESTRATIFICATION_MINIMAL_AND_MAXIMAL_SPEED: 'L’écoulement a une vitesse minimale de 0,1 m/s et maximale de 0,3 m/s au sol',
    LABEL_HORIZONTAL_DESTRATIFICATION_MINIMAL_AND_MAXIMAL_SPEED: 'L’écoulement a une vitesse minimale de 0,01 m/s et maximale de 0,3 m/s en tout point du local',
    LABEL_VERTICAL_ASPIRATION_AIR_PLAFOND: 'L’aspiration de l’air s’effectue à au plus un mètre du plafond',
    LABEL_HORIZONTAL_HIGHER_ASPIRATION_AIR_PLAFOND: 'Le point le plus haut de l’aspiration se situe à au plus un mètre du plafond',
    LABEL_HORIZONTAL_LOWER_ASPIRATION_AIR_PLAFOND: 'Le point le plus bas de l’aspiration se situe à au plus un mètre du sol',
    LABEL_DESTRATIFICATEUR_ASSERVI_A_UNE_MESURE_DE_TEMPERATURE:
        'Le système de déstratification d’air est asservi à une mesure de température de l’air dans la zone située entre le déstratificateur et le plafond',
    LABEL_DESTRATIFICATEUR_BRUIT_SOL: 'Le niveau du bruit au sol du fait du fonctionnement du système est strictement inférieur à 45 dB',
    LABEL_DESTRATIFICATEUR_CHAUFFE_AIR: 'Le système de déstratification d’air installé ne permet pas de chauffer l’air',
    LABEL_DESTRATIFICATEUR_HAUTEUR_PLAFOND: 'Hauteur sous plafond ou sous faîtage du local (en m)',
    LABEL_ENERGY_EFFICIENCY_VITESSE_AIR_SOL: 'Vitesse de l’air au sol (en m/s)',
    LABEL_ENERGY_EFFICIENCY_NIVEAU_DE_BRUIT_AU_SOL: 'Niveau de bruit au sol (en db)',
    LABEL_PUISSANCE_NOMINAL_RADIATIF: 'Puissance nominale du système de chauffage radiatif du local (en kW)',
    LABEL_PUISSANCE_NOMINAL_CONVECTIF: 'Puissance nominale du système de chauffage convectif du local (en kW)',
    LABEL_TYPE_LOCAL_CHAUFFAGE: 'Le local est chauffé par un',
    LABEL_DESTRATIFICATION_DISPOSITIF_MELANGE_AIR: 'Le système de déstratification contient un dispositif permettant le mélange de l’air aspiré',
    LABEL_NOMBRE_EMETEUR_CONVECTIF: 'Nombre d’émetteur convectif',
    LABEL_NOMBRE_EMETEUR_RADIATIF: 'Nombre d’émetteur radiatif',
    LABEL_NUMBER_OF_DESTRATIFICATEUR: 'Nombre de destratificateur',

});

export const batTh142VA543AndProductsValueTranslation = Object.freeze({
    VERTICAL: 'Vertical',
    HORIZONTAL: 'Horizontal',
    SYSTEME_CONVECTIF: 'Système convectif',
    SYSTEME_RADIATIF: 'Système radiatif',
    SYSTEME_RADIATIF_AND_SYSTEME_CONVECTIF: 'Système convectif et système radiatif',
    SPORT_OR_TRANSPORT: 'Local dédié aux activités sportives ou aux transports',
    COMMERCE_CULTE_CONF: 'Local dédié au commerce, aux spectacles ou conférences, aux loisirs ou aux lieux de culte',
});

export const batTh142VA543AndsProductsAsKeys = Object.keys(batTh142VA543AndProductsValueTranslation).reduce((acc, key) => ({ ...acc, [`${key}`]: key }), {});

export const ecoulementTypeOptions = _.pickBy(batTh142VA543AndProductsValueTranslation, (value, key) =>
    [batTh142VA543AndsProductsAsKeys.VERTICAL, batTh142VA543AndsProductsAsKeys.HORIZONTAL].includes(key)
);

export const localSystemOptions = _.pickBy(batTh142VA543AndProductsValueTranslation, (value, key) =>
    [batTh142VA543AndsProductsAsKeys.SYSTEME_CONVECTIF, batTh142VA543AndsProductsAsKeys.SYSTEME_RADIATIF, batTh142VA543AndsProductsAsKeys.SYSTEME_RADIATIF_AND_SYSTEME_CONVECTIF].includes(key)
);

export const localTypeOptions = _.pickBy(batTh142VA543AndProductsValueTranslation, (value, key) =>
    [batTh142VA543AndsProductsAsKeys.SPORT_OR_TRANSPORT, batTh142VA543AndsProductsAsKeys.COMMERCE_CULTE_CONF].includes(key)
);
