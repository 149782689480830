export const CONTRACTS_QUERY_KEY = 'contracts';
export const PRODUCTS_QUERY_KEY = 'products';
export const PRODUCTS_ANALYTICS_QUERY_KEY = 'products-analytics';
export const UPLOAD_DOCUMENTS_QUERY_KEY = 'upload-documents';
export const UPLOAD_DOCUMENT_QUERY_KEY = 'upload-document';
export const DOSSIERS_QUERY_KEY = 'dossiers';
export const DOSSIERS_FILTERS_QUERY_KEY = 'dossiers-filters';

export const ALL_FILTER_VALUE = 'all';

export const DOSSIERS_EXPORT_FILE_NAME = 'exports_dossiers_renolib_';
