import PropTypes from 'prop-types';

import {
    applicationPages,
    appointmentFileTypes,
    appointmentModalTypes,
    appointmentNotifiedAccountsEnum,
    appointmentStatuses,
    categoryTypes,
    customerTypes,
    fileTypes,
    formModes,
    personStatus,
    reminderOptions,
    wasteManagementUnitsEnum,
    wasteTypeEnum,
} from './enums';

/* Properties */
const addressProperties = {
    streetNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    streetName: PropTypes.string,
    cadastralParcelNumber: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
};

/* PropTypes */
const addressPropType = PropTypes.shape(addressProperties);

const labelValuePropTypes = PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
});

const administrativeAddressPropTypes = PropTypes.shape(addressProperties);

const accountPropTypes = PropTypes.shape({
    gender: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
});

const accountsPropType = PropTypes.arrayOf(accountPropTypes);

const organizationPropTypes = PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
    commercialName: PropTypes.string,
    type: PropTypes.string,
    capital: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    naf: PropTypes.string,
    ape: PropTypes.string,
    siret: PropTypes.number,
    vat: PropTypes.string,
    organizationAddresses: PropTypes.arrayOf(
        PropTypes.shape({
            isMainAddress: PropTypes.bool,
            streetNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            streetName: PropTypes.string,
            postalCode: PropTypes.string,
            city: PropTypes.string,
        })
    ),
    organizationBilling: PropTypes.shape({
        phone: PropTypes.string,
        email: PropTypes.string,
        advancePayment: PropTypes.number,
        orderBy: PropTypes.string,
        bic: PropTypes.string,
        iban: PropTypes.string,
        paymentDelay: PropTypes.number,
        latePenalties: PropTypes.number,
        hideLatePenalties: PropTypes.bool,
        recoveryFees: PropTypes.number,
    }),
    organizationRGECertificates: PropTypes.arrayOf(
        PropTypes.shape({
            reference: PropTypes.string,
        })
    ),
    organizationInsurances: PropTypes.arrayOf(
        PropTypes.shape({
            reference: PropTypes.string,
            name: PropTypes.string,
            city: PropTypes.string,
            warrantyArea: PropTypes.string,
        })
    ),
});

const renovationPremiumTypes = PropTypes.shape({
    representativeType: PropTypes.string,
    mixedMandateStatus: PropTypes.string,
    maPrimeRenovEligibilityConditionMessages: PropTypes.arrayOf(PropTypes.string),
    maPrimeRenovEligibilityByOperations: PropTypes.arrayOf(
        PropTypes.shape({
            operationSheetName: PropTypes.string,
            amount: PropTypes.number,
            eligibilityMessage: PropTypes.string,
            eligible: PropTypes.bool,
        })
    ),
});
const quotationPropTypes = PropTypes.shape({
    quotationNumber: PropTypes.string,
    customer: PropTypes.shape({
        isCustomerPM: PropTypes.bool,
        customerPM: PropTypes.shape({
            commercialName: PropTypes.string,
        }),
        customerPP: PropTypes.shape({
            lastName: PropTypes.string,
            firstName: PropTypes.string,
        }),
    }),
    quotationDate: PropTypes.string,
    operations: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
        })
    ),
    renovationPremium: renovationPremiumTypes,
    prices: PropTypes.shape({
        energySavingCertificatePremium: PropTypes.number,
    }),
    status: PropTypes.string,
    uuid: PropTypes.string,
});

const quotationValiditiesPropTypes = PropTypes.shape({
    ORGANIZATION: PropTypes.shape({
        isValid: PropTypes.bool.isRequired,
        messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    IDENTIFICATION: PropTypes.shape({
        isValid: PropTypes.bool.isRequired,
        messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    BILLING_INFORMATIONS: PropTypes.shape({
        isValid: PropTypes.bool.isRequired,
        messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    CERTIFICATES: PropTypes.shape({
        isValid: PropTypes.bool.isRequired,
        messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
});

const administrativeAddressPropType = PropTypes.shape({
    addressName: PropTypes.string,
    fullAddressText: PropTypes.string,
    streetNumber: PropTypes.string,
    streetName: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    isMainAddress: PropTypes.bool,
});

const administrativeAddressesPropType = PropTypes.arrayOf(administrativeAddressPropType);

const administrativeContactPropType = PropTypes.shape({
    gender: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    addressId: PropTypes.string,
    isMainAddress: PropTypes.bool,
});

const administrativeContactsPropType = PropTypes.arrayOf(administrativeContactPropType);

const renovationAddressPropType = PropTypes.shape({
    uuid: PropTypes.string,
    customerType: PropTypes.string,
    addressName: PropTypes.string,
    streetNumber: PropTypes.string,
    streetName: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    climatZone: PropTypes.string,
    homeAddress: PropTypes.bool,
    phone: PropTypes.string,
    buildingSector: PropTypes.string,
    housingType: PropTypes.string,
    activityArea: PropTypes.string,
});

const renovationAddressesPropType = PropTypes.arrayOf(renovationAddressPropType);

const personalInformationProperties = {
    gender: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    taxNoticeNumber: PropTypes.string,
    taxNoticeReference: PropTypes.string,
    referenceTaxIncome: PropTypes.number,
    numberOfDependents: PropTypes.number,
    ...addressProperties,
};

const customerPPPropType = PropTypes.shape({
    uuid: PropTypes.string,
    customerNumber: PropTypes.string,
    ...personalInformationProperties,
    renovationAddresses: renovationAddressesPropType,
});

const customersPPPropType = PropTypes.arrayOf(customerPPPropType);

const personalInformationPropType = PropTypes.shape(personalInformationProperties);

const occupantPropType = PropTypes.shape({
    gender: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    taxNoticeNumber: PropTypes.string,
    taxNoticeReference: PropTypes.string,
    referenceTaxIncome: PropTypes.number,
    numberOfDependents: PropTypes.number,
});

const housingPropType = PropTypes.shape({
    number: PropTypes.string,
    occupants: PropTypes.arrayOf(occupantPropType),
});

const organizationProperties = {
    uuid: PropTypes.string,
    activity: PropTypes.string,
    siren: PropTypes.number,
    siret: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    commercialName: PropTypes.string,
    vat: PropTypes.string,
    ape: PropTypes.string,
    naf: PropTypes.string,
};

const customerPMPropType = PropTypes.shape({
    ...organizationProperties,
    customerNumber: PropTypes.string,
    logoFileDownloadUrl: PropTypes.string,
    logoFileId: PropTypes.string,
    addresses: PropTypes.arrayOf(administrativeAddressPropType),
    contacts: PropTypes.arrayOf(administrativeContactPropType),
});

const customersPMPropType = PropTypes.arrayOf(customerPMPropType);

const blockValidityPropType = PropTypes.shape({
    valid: PropTypes.bool,
    validated: PropTypes.bool,
    compliant: PropTypes.bool,
    errorMessages: PropTypes.arrayOf(PropTypes.string),
});

const quotationAddressPropType = PropTypes.shape({});

const quotationCustomerPropType = PropTypes.shape({});

const quotationProductPropType = PropTypes.shape({});

const quotationOperationPropType = PropTypes.shape({});

const quotationOperationsPropType = PropTypes.arrayOf(quotationOperationPropType);

const quotationPropType = PropTypes.shape({
    uuid: PropTypes.string,
    status: PropTypes.string,
    quotationNumber: PropTypes.string,
    quotationIssueDate: PropTypes.string,
    quotationExpirationDate: PropTypes.string,
    personalizedText: PropTypes.string,
    organization: PropTypes.shape({}),
    customer: PropTypes.shape({
        customerType: PropTypes.string,
        customerData: PropTypes.shape({}),
    }),
    address: PropTypes.shape({}),
    operations: PropTypes.arrayOf(PropTypes.shape({})),
    contract: PropTypes.shape({}),
    pricesPerVatRate: PropTypes.arrayOf(PropTypes.shape({})),
    requestedAmountToPayByBeneficiary: PropTypes.number,
    totalPriceToPay: PropTypes.number,
    energySavingCertificatePremium: PropTypes.number,
    blockValidity: blockValidityPropType,
});

const quotationsPropType = PropTypes.arrayOf(quotationPropType);

const operationFormPropTypes = {
    operation: PropTypes.shape({}),
    address: PropTypes.shape({}),
    onChange: PropTypes.func,
    onUpdateInstallationDetailsToSpecify: PropTypes.func,
    onUpdateExclusionDetailsToSpecify: PropTypes.func,
    onUpdateOtherDetailsToSpecify: PropTypes.func,
};

const productFormPropTypes = {
    operation: quotationOperationPropType,
    formErrors: PropTypes.shape({}),
    formState: PropTypes.shape({}),
    onChange: PropTypes.func,
    isUpdatable: PropTypes.bool,
};

const invoicePropType = PropTypes.shape({
    uuid: PropTypes.string,
    invoiceNumber: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    invoiceIssueDate: PropTypes.string,
    totalPrice: PropTypes.number,
    operations: PropTypes.arrayOf(PropTypes.shape({})),
    quotationNumber: PropTypes.string,
    personalizedText: PropTypes.string,
    organization: PropTypes.shape({}),
    energySavingCertificatePremium: PropTypes.number,
    quotationTotalPriceToPay: PropTypes.number,
    customer: PropTypes.oneOfType([
        PropTypes.shape({
            customerType: PropTypes.string,
            customerData: PropTypes.shape({}),
        }),
        PropTypes.string,
    ]),
    address: PropTypes.shape({}),
    contract: PropTypes.shape({}),
    billing: PropTypes.shape({}),
    idQuotation: PropTypes.string,
    idOrganization: PropTypes.string,
    pricesPerVatRate: PropTypes.arrayOf(PropTypes.shape({})),
});

const invoicesPropType = PropTypes.arrayOf(invoicePropType);

const childrenPropType = PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]);

const dossierCEEPropType = PropTypes.shape({});

const dossiersCEEPropType = PropTypes.arrayOf(dossierCEEPropType);

const documentPropType = PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    available: PropTypes.bool,
    valid: PropTypes.bool,
    fileDownloadUrl: PropTypes.string,
    fileDownloadUrls: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(
            PropTypes.shape({
                fileDownloadUrl: PropTypes.string,
                comment: PropTypes.string,
            })
        ),
    ]),
    fileType: PropTypes.string,
    position: PropTypes.number,
    message: PropTypes.string,
});

const documentsPropType = PropTypes.arrayOf(documentPropType);

const uploadDocumentPropType = PropTypes.shape({});

const uploadDocumentsPropType = PropTypes.arrayOf(uploadDocumentPropType);

const productCataloguePropType = PropTypes.shape({});

const productsCataloguePropType = PropTypes.arrayOf(productCataloguePropType);

const operationPricesPropType = PropTypes.arrayOf(
    PropTypes.shape({
        operation: PropTypes.string,
        price: PropTypes.number,
        vatRate: PropTypes.number,
    })
);

const productVariationPropType = PropTypes.shape({
    uuid: PropTypes.string,
    length: PropTypes.number,
    thickness: PropTypes.number,
    diameter: PropTypes.number,
});

const formToDisplayPropType = PropTypes.oneOf(['default', 'details', 'operations', 'addOperations', 'detailsOtherProduct', 'editOperation']);

const sendDocumentsFormStatePropType = PropTypes.shape({
    email: PropTypes.string,
});

const sendDocumentsDocumentsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        title: PropTypes.string,
        fileDownloadUrl: PropTypes.string,
    })
);

const sendDocumentsModalOptionsPropType = PropTypes.shape({
    modalTitle: PropTypes.string,
    actionTitle: PropTypes.string,
});

const quotationTableFunctionsPropTypes = {
    onOpenQuotation: PropTypes.func.isRequired,
    onDeleteQuotation: PropTypes.func,
    onCreateInvoice: PropTypes.func,
    onOpenSendQuotationByEmailModal: PropTypes.func,
    onSetQuotationSentToCustomer: PropTypes.func,
    onDuplicateQuotation: PropTypes.func,
};

const dossiersCEETableFunctionsPropTypes = {
    onOpenDossierCEE: PropTypes.func.isRequired,
    onSendDossierCEE: PropTypes.func,
};

const tableOperationsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
    })
);

const applicationPagePropType = PropTypes.oneOf(Object.values(applicationPages));

const subContractorPropType = PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
    commercialName: PropTypes.string,
    type: PropTypes.string,
    capital: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    naf: PropTypes.string,
    ape: PropTypes.string,
    siret: PropTypes.number,
    vat: PropTypes.string,
    addresses: administrativeAddressesPropType,
    contacts: administrativeContactsPropType,
    rgeCertificates: PropTypes.arrayOf(
        PropTypes.shape({
            reference: PropTypes.string,
        })
    ),
});

const authFormPropTypes = {
    formState: PropTypes.shape({}),
    formErrors: PropTypes.shape({}),
    formParams: PropTypes.shape({
        emailDisabled: PropTypes.bool,
        requesting: PropTypes.bool,
    }),
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
};

const customerAnalyticsPropType = PropTypes.shape({
    totalOperationsRealized: PropTypes.number,
    totalOperationsVolume: PropTypes.number,
});

const rgeCertificatePropType = PropTypes.shape({});

const rgeCertificatesPropType = PropTypes.arrayOf(rgeCertificatePropType);

const remoteRGECertificatePropType = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string,
    domains: PropTypes.arrayOf(PropTypes.string),
    fileId: PropTypes.string,
});

const remoteRGECertificatesPropType = PropTypes.arrayOf(remoteRGECertificatePropType);

const formModePropType = PropTypes.oneOf(Object.values(formModes));

const formPropTypes = {
    currentFormIndex: PropTypes.number,
    formMode: formModePropType,
    formState: PropTypes.shape({}),
    formErrors: PropTypes.shape({}),
    formsStates: PropTypes.arrayOf(PropTypes.shape({})),
    formsErrors: PropTypes.arrayOf(PropTypes.shape({})),
    formData: PropTypes.shape({}),
    onChange: PropTypes.func,
    onCurrentFormIndexChange: PropTypes.func,
    onFileChange: PropTypes.func,
    onResetForms: PropTypes.func,
};

const modalPropTypes = {
    ...formPropTypes,
    title: PropTypes.string,
    show: PropTypes.bool,
    modalData: PropTypes.shape({}),
    onHide: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
};

const renovationAddressFormDataPropType = PropTypes.shape({
    customerType: PropTypes.oneOf(Object.values(customerTypes)),
    homeRenovationAddress: addressPropType,
    homeAddressAlreadySetAsRenovationAddress: PropTypes.bool,
});

const contractPropType = PropTypes.shape({
    billingAddress: PropTypes.string,
    billingEmail: PropTypes.string,
    contractAmendments: PropTypes.arrayOf(PropTypes.shape({})),
    daysOfPaymentDelay: PropTypes.number,
    delegateeName: PropTypes.string,
    endDate: PropTypes.string,
    mandatoryDetails: PropTypes.string,
    prices: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.number,
            label: PropTypes.string,
        })
    ),
    reference: PropTypes.string,
    signatoryEmail: PropTypes.string,
    signatoryFirstName: PropTypes.string,
    signatoryFunction: PropTypes.string,
    signatoryName: PropTypes.string,
    signatoryPhone: PropTypes.string,
    signatureDate: PropTypes.string,
    startDate: PropTypes.string,
    status: PropTypes.string,
    uuid: PropTypes.string,
    fileDownloadUrl: PropTypes.string,
});

const contractsPropType = PropTypes.arrayOf(contractPropType);

const exportsAvailabilitiesPropType = PropTypes.shape({
    INVOICE: PropTypes.shape({}),
});

const uploadedDocumentPropType = PropTypes.shape({
    fileId: PropTypes.string,
    fileDownloadUrl: PropTypes.string,
    unsignedFileId: PropTypes.string,
    unsignedFileDownloadUrl: PropTypes.string,
});

const categoryTypePropType = PropTypes.oneOf(Object.values(categoryTypes));

const categoryPropType = PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.string,
    markerColor: PropTypes.string,
    organization: PropTypes.string,
    type: categoryTypePropType,
    deleted: PropTypes.bool,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    uuid: PropTypes.string,
    __v: PropTypes.number,
});

const categoryModalPropType = {
    editableCategory: categoryPropType,
    onHideModal: PropTypes.func.isRequired,
    uuidAccount: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(categoryPropType),
};

const categoriesTablePropType = {
    categories: PropTypes.arrayOf(categoryPropType),
    onDeleteCategory: PropTypes.func.isRequired,
    onUpdateCategory: PropTypes.func.isRequired,
};

const categoriesTableItemPropType = {
    category: categoryPropType,
    onUpdateCategory: PropTypes.func.isRequired,
    onDeleteCategory: PropTypes.func.isRequired,
    editIcon: PropTypes.string.isRequired,
    deleteIcon: PropTypes.string.isRequired,
};

const appointmentFilePropType = PropTypes.shape({
    appointment: PropTypes.string,
    createdAt: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    publicId: PropTypes.string,
    secureUrl: PropTypes.string,
    type: PropTypes.oneOf(Object.values(appointmentFileTypes)),
    updatedAt: PropTypes.string,
    uuid: PropTypes.string,
    __v: PropTypes.number,
    _id: PropTypes.string,
});

const notificationDataCustomerPropType = PropTypes.shape({
    _id: PropTypes.string,
    customer: PropTypes.object,
    customerType: PropTypes.oneOf([...Object.values(customerTypes), '']),
    status: PropTypes.oneOf(Object.values(personStatus)),
    notificationType: PropTypes.oneOf(Object.values(appointmentNotifiedAccountsEnum)),
    email: PropTypes.string,
    phone: PropTypes.string,
});

const notificationDataOtherPersonPropType = PropTypes.shape({
    _id: PropTypes.string,
    uuid: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.oneOf(Object.values(personStatus)),
    notificationType: PropTypes.oneOf(Object.values(appointmentNotifiedAccountsEnum)),
    phone: PropTypes.string,
});

const notificationDataTechnicianPropType = PropTypes.shape({
    technicianAccountUUID: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.oneOf(Object.values(personStatus)),
    notificationType: PropTypes.oneOf(Object.values(appointmentNotifiedAccountsEnum)),
    phone: PropTypes.string,
});

const appointmentPropType = PropTypes.shape({
    reminder: PropTypes.oneOf(Object.values(reminderOptions)),
    reminderSent: PropTypes.bool,
    appointmentCategory: categoryPropType,
    technicianComment: PropTypes.string,
    customerComment: PropTypes.string,
    uploadFiles: PropTypes.arrayOf(appointmentFilePropType),
    notificationDataCustomer: notificationDataCustomerPropType,
    deleted: PropTypes.bool,
    notificationDataOtherPersons: PropTypes.arrayOf(notificationDataOtherPersonPropType),
    startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    title: PropTypes.string,
    address: PropTypes.string,
    organization: PropTypes.string,
    notificationDataTechnicians: PropTypes.arrayOf(notificationDataTechnicianPropType),
    createdAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    updatedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    status: PropTypes.oneOf(Object.values(appointmentStatuses)),
    id: PropTypes.string,
    uuid: PropTypes.string,
    __v: PropTypes.number,
    _id: PropTypes.string,
});

const appointmentCommentPropType = {
    type: PropTypes.oneOf(['read', 'modify']).isRequired,
    onChange: PropTypes.func,
    textAreaName: PropTypes.string,
    fileFieldName: PropTypes.string,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    uploadedFiles: PropTypes.arrayOf(appointmentFilePropType),
    downloadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    possibleToDeleteFile: PropTypes.bool,
    errors: PropTypes.object,
};

const appointmentDocumentPropType = {
    file: PropTypes.oneOfType([appointmentFilePropType, PropTypes.object]),
    onRemove: PropTypes.func,
    onDownloadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    documentType: PropTypes.oneOf(Object.values(fileTypes)).isRequired,
    possibleToDeleteFile: PropTypes.bool,
};

const customerDataPropType = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    notificationType: PropTypes.oneOf(Object.values(appointmentNotifiedAccountsEnum)),
});

const customerCredentialsPropType = {
    onCustomerCredentialsChange: PropTypes.func.isRequired,
    customerData: customerDataPropType,
    errors: PropTypes.object,
};

const updateAppointmentOtherPersonPropType = PropTypes.shape({
    cardId: PropTypes.string,
    uuid: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    notificationType: PropTypes.oneOf(Object.values(appointmentNotifiedAccountsEnum)),
});

const contactBlockPropType = {
    errors: PropTypes.object,
    title: PropTypes.string,
    addButtonText: PropTypes.string,
    deleteContact: PropTypes.func,
    technician: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    initialContacts: PropTypes.arrayOf(updateAppointmentOtherPersonPropType),
    existingContacts: PropTypes.arrayOf(updateAppointmentOtherPersonPropType),
};

const appointmentsTablePropType = {
    appointments: PropTypes.arrayOf(appointmentPropType),
    uuidAccount: PropTypes.string.isRequired,
    selectedAppointments: PropTypes.arrayOf(appointmentPropType),
    setSelectedAppointments: PropTypes.func,
    totalAppointments: PropTypes.number,
    currentPage: PropTypes.number,
    perPage: PropTypes.number,
    onReminderClick: PropTypes.func,
};

const appointmentsTableItemPropType = {
    appointment: appointmentPropType,
    isAdminRole: PropTypes.bool,
    selectedAppointments: PropTypes.arrayOf(appointmentPropType),
    toggleSelectAppointment: PropTypes.func,
    onEditAppointment: PropTypes.func,
    onDeleteAppointment: PropTypes.func,
    onAppointmentClick: PropTypes.func,
    onReminderClick: PropTypes.func,
    handleOpenCustomerPPDetails: PropTypes.func,
    handleOpenCustomerPMDetails: PropTypes.func,
};

const appointmentFormWrapperPropType = {
    appointment: appointmentPropType,
    onHide: PropTypes.func.isRequired,
    uuidAccount: PropTypes.string.isRequired,
    initialAppointmentFormState: PropTypes.object.isRequired,
    modalType: PropTypes.oneOf(Object.values(appointmentModalTypes)).isRequired,
};

const appointmentFormPropType = {
    modalType: PropTypes.oneOf(Object.values(appointmentModalTypes)).isRequired,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired,
    appointmentModal: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onCreateCategory: PropTypes.func.isRequired,
    onDeleteAppointment: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    uploadFiles: PropTypes.arrayOf(appointmentFilePropType),
    isUpdateModal: PropTypes.bool,
    categories: PropTypes.arrayOf(categoryPropType),
    notifyOtherPersons: PropTypes.arrayOf(updateAppointmentOtherPersonPropType),
    notifyTechnicians: PropTypes.arrayOf(updateAppointmentOtherPersonPropType),
};

const calendarTimeTableCellPropTypes = {
    startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    onDoubleClick: PropTypes.func,
};

const fieldOptionsPropType = PropTypes.oneOfType([
    PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    PropTypes.arrayOf(PropTypes.string),
]);

const fieldPropTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    options: fieldOptionsPropType,
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            options: fieldOptionsPropType,
        })
    ),
    onChange: PropTypes.func,
};

const paginatePropTypes = PropTypes.shape({
    limit: PropTypes.number,
    skip: PropTypes.number,
    count: PropTypes.number,
    currentPage: PropTypes.number,
});

const wasteCollectionDepotPropType = PropTypes.shape({
    uuid: PropTypes.string,
    socialReason: PropTypes.string,
    streetName: PropTypes.string,
    streetNumber: PropTypes.string,
    organization: PropTypes.string,
    siren: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
});

const wasteTypePropType = PropTypes.shape({
    type: PropTypes.oneOf(Object.values(wasteTypeEnum)),
    uuid: PropTypes.string,
    price: PropTypes.number,
    totalPrice: PropTypes.number,
    quantity: PropTypes.number,
    vatRate: PropTypes.number,
    unit: PropTypes.oneOf(Object.values(wasteManagementUnitsEnum)),
    wasteCollectionDepots: PropTypes.arrayOf(wasteCollectionDepotPropType),
});

const wasteManagementPropTypes = PropTypes.shape({
    wasteModalities: PropTypes.string,
    blockValidity: blockValidityPropType,
    wasteTypes: PropTypes.arrayOf(wasteTypePropType),
});

const coupDePouceOperationsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        type: PropTypes.shape,
        operationSheetName: PropTypes.string,
        operationName: PropTypes.string,
    })
);

const invoiceableDelegateePropType = PropTypes.shape({
    name: PropTypes.string,
    siren: PropTypes.number,
    addressText: PropTypes.string,
});

const invoiceableDelegateesPropType = PropTypes.arrayOf(invoiceableDelegateePropType);

const delegateeInvoiceCustomerPropType = PropTypes.shape({
    name: PropTypes.string,
    siren: PropTypes.number,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
});

const delegateeInvoiceOperationPropType = PropTypes.shape({
    uuid: PropTypes.string,
    operationSheetName: PropTypes.string,
    formattedOperationSheetName: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({})),
});

const delegateeInvoiceBillingNotePropType = PropTypes.shape({
    reference: PropTypes.string,
    date: PropTypes.string,
});

const delegateeInvoicePropType = PropTypes.shape({
    customer: delegateeInvoiceCustomerPropType,
});

const delegateeInvoicesPropType = PropTypes.arrayOf(delegateeInvoicePropType);

const pdfViewerPropType = PropTypes.shape({
    testId: PropTypes.string,
    file: PropTypes.object,
    downloadUrl: PropTypes.string,
    large: PropTypes.bool,
    full: PropTypes.bool,
    minHeight: PropTypes.string,
    toolbarHidden: PropTypes.bool,
    onOpen: PropTypes.func,
    openTitle: PropTypes.string,
});

export const organizationStampType = PropTypes.shape({ fileDownloadUrl: PropTypes.string, fileId: PropTypes.string, fileName: PropTypes.string });

const sidebarDocumentPropType = PropTypes.shape({
    type: PropTypes.string,
    uuid: PropTypes.string,
});

const selectOptionPropType = PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
});

const productPropType = PropTypes.shape({
    key: PropTypes.string,
    uuid: PropTypes.string,
    category: PropTypes.string,
    title: PropTypes.string,
    brand: PropTypes.string,
    reference: PropTypes.string,
    certification: PropTypes.string,
    energyEfficiencyProperties: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    textArea: PropTypes.string,
    price: PropTypes.string,
    vatRate: PropTypes.number,
    isProductUpdatableOrDeletable: PropTypes.bool,
});

export {
    wasteManagementPropTypes,
    wasteTypePropType,
    wasteCollectionDepotPropType,
    renovationPremiumTypes,
    addressProperties,
    administrativeAddressPropTypes,
    accountPropTypes,
    organizationPropTypes,
    quotationPropTypes,
    quotationValiditiesPropTypes,
    administrativeAddressPropType,
    customerPPPropType,
    customersPPPropType,
    personalInformationPropType,
    renovationAddressPropType,
    renovationAddressesPropType,
    housingPropType,
    occupantPropType,
    customerPMPropType,
    customersPMPropType,
    administrativeAddressesPropType,
    administrativeContactsPropType,
    administrativeContactPropType,
    blockValidityPropType,
    quotationPropType,
    quotationsPropType,
    operationFormPropTypes,
    childrenPropType,
    quotationAddressPropType,
    quotationCustomerPropType,
    quotationProductPropType,
    quotationOperationsPropType,
    quotationOperationPropType,
    productFormPropTypes,
    accountsPropType,
    invoicePropType,
    invoicesPropType,
    dossierCEEPropType,
    dossiersCEEPropType,
    documentPropType,
    documentsPropType,
    uploadDocumentPropType,
    uploadDocumentsPropType,
    productsCataloguePropType,
    productCataloguePropType,
    operationPricesPropType,
    productVariationPropType,
    formToDisplayPropType,
    sendDocumentsFormStatePropType,
    sendDocumentsDocumentsPropType,
    sendDocumentsModalOptionsPropType,
    quotationTableFunctionsPropTypes,
    dossiersCEETableFunctionsPropTypes,
    tableOperationsPropType,
    applicationPagePropType,
    subContractorPropType,
    authFormPropTypes,
    productPropType,
    customerAnalyticsPropType,
    rgeCertificatesPropType,
    remoteRGECertificatePropType,
    remoteRGECertificatesPropType,
    addressPropType,
    modalPropTypes,
    formModePropType,
    renovationAddressFormDataPropType,
    formPropTypes,
    contractPropType,
    labelValuePropTypes,
    contractsPropType,
    exportsAvailabilitiesPropType,
    categoryPropType,
    categoryModalPropType,
    categoriesTablePropType,
    categoriesTableItemPropType,
    appointmentPropType,
    appointmentCommentPropType,
    appointmentDocumentPropType,
    uploadedDocumentPropType,
    customerDataPropType,
    customerCredentialsPropType,
    contactBlockPropType,
    appointmentsTablePropType,
    appointmentsTableItemPropType,
    appointmentFormWrapperPropType,
    appointmentFormPropType,
    calendarTimeTableCellPropTypes,
    fieldPropTypes,
    paginatePropTypes,
    coupDePouceOperationsPropType,
    invoiceableDelegateesPropType,
    delegateeInvoiceCustomerPropType,
    delegateeInvoicePropType,
    delegateeInvoiceOperationPropType,
    delegateeInvoiceBillingNotePropType,
    delegateeInvoicesPropType,
    pdfViewerPropType,
    sidebarDocumentPropType,
    selectOptionPropType,
};
